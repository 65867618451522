<template>
  <section class="orders" id="printableArea">
    <div class="row">
      <div class="col-12 col-md-6">
        <img src="/vitesy_logo.png" height="50px" width="auto" alt="Vitesy Logo"/>
      </div>
      <div class="col-12 col-md-6 text-right">
        <small>
          <strong>Laboratori Fabrici Srl</strong><br>
          Piazzetta Ado Furlan, 4 - 33170 Pordenone (PN)<br>
          Cap. Soc.€ 4.004,60 - Cod.Fiscale e P.IVA IT01786900934<br>
          Iscr. Reg. Imprese PN 01786900934 - REA PN 104531<br>
          www.vitesy.com - info@labfabrici.com - +39 320 054 2762
        </small>
      </div>
    </div>
    <div v-if="$router.user.roleScore <= 1 || $router.user.roleScore >= 2">
      <template v-if="data.type === 'power-supply'">
        <div class="mt-3 mb-5" v-for="t in data" :key="t._id">
          <div class="row mb-4">
            <div class="col-8">
              <h3 class="noPrint">Microline Test {{ id }}</h3>
              <small class="noPrint">Uploaded at: {{ t.createdAt | formatDate }}</small>
            </div>
            <div class="col-4 text-right">
              <button class="btn ups noPrint ml-4" @click="print('printableArea')"><i class="fa fa-print"></i> Print</button>
            </div>
          </div>
          <table class="table table-bordered table-narrow">
            <tr>
              <th>Id</th>
              <td>{{ t.data.Id }}</td>
              <th>Operator</th>
              <td>{{ t.data.OperatorName }}</td>
              <th>Test rig</th>
              <td>{{ t.data.TestRigName }}</td>
            </tr>
            <tr>
              <th>Part Number</th>
              <td>{{ t.data.PartNumber }}</td>
              <th>Serial Number</th>
              <td>{{ t.data.SerialNumber }}</td>
              <th>Sequence Name</th>
              <td>{{ t.data.SequenceName }}</td>
            </tr>
            <tr>
              <th>Duration (s)</th>
              <td>{{ t.data.Duration }}</td>
              <th>Test date</th>
              <td>{{ t.data.DateStart | formatDate }}</td>
              <th>Outcome</th>
              <td :class="t.data.Outcome > 0 ? 'bg-green': 'bg-red'">
                <h6 class="mb-0 text-center"><strong>{{ t.data.Outcome > 0 ? 'PASS': 'FAIL' }}</strong></h6>
              </td>
            </tr>
          </table>
          <template v-for="task in t.data.Tasks">
            <table v-if="!task.TaskType.includes('Header')" :key="task.Id" class="mt-4 table table-bordered table-narrow">
              <thead>
                <tr>
                  <th colspan="2" class="bg-grey"><h6 class="mb-0"><strong>{{ task.TaskName }}</strong></h6></th>
                  <td colspan="2" :class="task.Outcome > 0 ? 'bg-green': 'bg-red'">
                    <h6 class="mb-0 text-center"><strong>{{ task.Outcome > 0 ? 'PASS': 'FAIL' }}</strong></h6>
                  </td>
                </tr>
              </thead>
              <tbody>
                <template v-for="(p, index) in task.Parms">
                  <tr v-if="index % 2 === 0" :key="p.ParmType">
                    <th>{{ p.ParmName }}</th>
                    <td>{{ p.StrValue }} {{ p.Unit }}</td>
                    <template v-if="task.Parms[index+1]">
                      <th>{{ task.Parms[index+1].ParmName }}</th>
                      <td>{{ task.Parms[index+1].StrValue }} {{ task.Parms[index+1].Unit }}</td>
                    </template>
                  </tr>
                </template>
              </tbody>
            </table>
          </template>
        </div>
      </template>
      <template v-else>
        <pre class="code" v-for="d in data" :key="d._id"><code>{{ d }}</code></pre>
      </template>
    </div>
    <div v-else>Access denied! You can't access this page.</div>
  </section>
</template>

<script>
import ProductionService from "@/services/ProductionService";
export default {
  name: "SingleProductionTest",
  data() {
    return {
      isLoading: false,
      data: false
    };
  },
  computed: {
    id(){
      return this.$route.params.id
    }
  },
  async mounted(){
    this.data = await this.getTest(this.id)
  },
  methods: {
    async getTest(id) {
      const data = await ProductionService.getProductionTest(id)
      return data.data
    },
    print(divName) {
      var printContents = document.getElementById(divName).innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/variables.scss";
.bg-grey {
  background-color: lightgray;
}
.bg-green {
  background-color: $green;
}
.bg-red {
  background-color: $red;
}
</style>
